import React from 'react';

import { useLocation } from '@reach/router';

import config from '../../config';

const CtaBar = () => {
  const { pathname } = useLocation();
  const urlList = ['/home-insurance/', '/car-insurance/', '/pet-insurance/'];
  const showCta = urlList.includes(pathname);

  const getQuoteUrl = (path: string) => {
    switch (path) {
      case '/car-insurance/':
        return config.CAR_URL;
      case '/pet-insurance/':
        return config.PET_URL;
      case '/home-insurance/':
      default:
        return config.HOME_URL;
    }
  };

  const retrieveQuoteUrl =
    pathname === '/car-insurance/'
      ? config.CAR_RETRIEVE_URL
      : config.HOME_LOGIN_URL;

  return showCta ? (
    <div className="w-full h-74px lg:h-97px bg-pxMenu flex flex-row">
      <div
        className={`w-full px-5 lg:px-6 mx-auto flex flex-row items-center space-x-5 max-w-560 lg:max-w-1390 justify-center lg:justify-start`}
      >
        <a
          href={getQuoteUrl(pathname)}
          className={`btn-primary cta-button w-6/12 lg:w-220px`}
        >
          Get a quote
        </a>

        <a
          href={retrieveQuoteUrl}
          className="btn-secondary cta-button w-6/12 lg:w-220px"
        >
          Retrieve quote
        </a>
      </div>
    </div>
  ) : null;
};

export default CtaBar;
