import { styled } from 'styled-components';

import { colors } from '../../styles/sharedStyled';

export const ChatContainer = styled.div`
  cursor: pointer;
  background-color: ${colors.purple9};
  color: ${colors.white};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: fixed !important;
  bottom: 0;
  right: 12px;
  width: 366px;
  height: 40px;
  padding: 10px 22px 15px;
  z-index: 1000;
  font-family: sans-serif;
  font-size: 15.5px;
  box-shadow: 0 0 7px rgb(0 0 0 / 30%);
  animation: 0.5s ease-out 0s 1 onappear;

  @media (max-width: 1024px) {
    transform: rotate(270deg);
    width: 138px;
    height: 44px;
    bottom: 50%;
    right: -50px;
    text-align: center;
    box-shadow: none;
  }
`;

export const ChatImage = styled.img`
  float: right;
  height: 0.75rem;
  margin-top: 0.375rem;
  padding-left: 0.25rem;

  @media (min-width: 1024px) {
    padding-left: 0;
  }
`;
