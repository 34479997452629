import React from 'react';

type ToggleSwitchProps = {
  toggle: boolean;
  handleToggle: () => void;
};

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  toggle,
  handleToggle,
}) => {
  const toggleClass = 'transform translate-x-5';

  return (
    <>
      <div
        className="w-40px h-20px flex items-center bg-white border rounded-full p-1px cursor-pointer"
        onClick={handleToggle}
      >
        <div
          data-testid="toggle-switch"
          className={`bg-${
            toggle ? 'pxPink' : 'pxCharcoal opacity-40'
          } w-15px h-15px rounded-full shadow-md transform duration-300 ease-in-out${
            toggle ? ` ${toggleClass}` : ''
          }`}
        ></div>
      </div>
    </>
  );
};

export default ToggleSwitch;
