import React, { createContext, FC, ReactNode, useState } from 'react';

type DisclaimerContextType = {
  currentDisclaimer: ReactNode;
  setCurrentDisclaimer: (disclaimer: ReactNode) => void;
};

export const DisclaimerContext = createContext<DisclaimerContextType | null>(
  null
);

type Props = { children: ReactNode };

const DisclaimerProvider: FC<Props> = ({ children }) => {
  const [currentDisclaimer, setCurrentDisclaimer] = useState<ReactNode>('');
  return (
    <DisclaimerContext.Provider
      value={{ currentDisclaimer, setCurrentDisclaimer }}
    >
      {children}
    </DisclaimerContext.Provider>
  );
};

export default DisclaimerProvider;
