import { initializeHypertune } from '../generated/generated';

const hypertune = initializeHypertune(
  {},
  {
    token: process.env.GATSBY_HYPERTUNE_TOKEN,
    shouldInitializeFromServer: typeof window !== 'undefined',
  }
);

export default hypertune;
