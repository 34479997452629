import { Environment } from '../generated/generated';

const getEnvironment = (environment: string): Environment => {
  switch (environment) {
    case 'uat':
      return 'UAT';
    case 'live-london':
      return 'LIVE';
    case 'local':
    default:
      return 'LOCAL';
  }
};

export default getEnvironment;
