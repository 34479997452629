import React, { FC, useState } from 'react';

import { useLocation } from '@reach/router';
import { Link } from 'gatsby';

import Arrow from '../../../static/svgIcons/RoundedArrow.svg';
import { useCookieModal } from '../../context/CookieModalProvider';
import { pushCookieConsentEventToDataLayer } from '../../services/windowService';
import ToggleSwitch from '../toggleSwitch/ToggleSwitch';

type CookieSectionProps = {
  title: string;
  description: string;
  id: string;
  isAlwaysActive: boolean;
  expanded: boolean;
  onToggle: (id: string) => void;
  onToggleSwitch: (id: string, value: boolean) => void;
  cookieValue: boolean;
};

const CookieSection: FC<CookieSectionProps> = ({
  id,
  title,
  description,
  isAlwaysActive,
  expanded,
  onToggle,
  onToggleSwitch,
  cookieValue,
}) => {
  const [toggle, setToggle] = useState(cookieValue || false);
  const { setShowModal } = useCookieModal();
  const { pathname } = useLocation();
  const isCookiePolicyPage = pathname === '/cookie-policy/';

  const handleToggleExpand = () => {
    onToggle(id);
  };

  const handleToggleSwitch = () => {
    setToggle(!toggle);
    onToggleSwitch(id, !toggle);
  };

  const handleMoreInformation = () => {
    pushCookieConsentEventToDataLayer(`more_information_${id}`);
    if (isCookiePolicyPage) {
      setShowModal(false);
    }
  };

  return (
    <div className="justify-between items-center flex-col gap-4 py-15px border-b border-solid">
      <div className="flex justify-between">
        <p className="flex text-fontSize-14 self-stretch items-center grow m-0 tracking-normal">
          {title}
        </p>

        <div className={`flex justify-between items-center gap-15px`}>
          {isAlwaysActive ? (
            <p className="text-sm self-stretch m-0 mr-15px tracking-normal text-pxPurple">
              Always active
            </p>
          ) : (
            <div className="self-stretch mr-15px">
              <ToggleSwitch toggle={toggle} handleToggle={handleToggleSwitch} />
            </div>
          )}

          <button
            onClick={handleToggleExpand}
            className="font-camptonBook text-fontSize-14 lg:text-fontSize-20 mb-0 relative pb-10px"
          >
            <Arrow
              data-testid="right-arrow-icon"
              className={`w-14px md:w-15px z-50 absolute top-0 right-0 transform ${
                expanded ? 'rotate-0' : 'rotate-180'
              }`}
            />
          </button>
        </div>
      </div>

      {expanded && (
        <div className="justify-center items-stretch bg-pxGrey w-full mt-4 p-15px rounded-xl">
          <p className="mb-2 text-fontSize-14 tracking-normal">{description}</p>
          <Link
            onClick={handleMoreInformation}
            to={'/cookie-policy/'}
            className="font-camptonSemiBold text-fontSize-14 tracking-normal"
          >
            More information
          </Link>
        </div>
      )}
    </div>
  );
};

export default CookieSection;
