import React, { createContext, FC } from 'react';

import useOpeningHours from '../hooks/useOpeningHours';

type WeekOpeningHours = { day: string; openingHours: string };

export type OpeningHoursContextType = {
  todaysOpeningHours: { home: string | null; motor: string | null };
  weekOpeningHours: {
    home: WeekOpeningHours[] | null;
    motor: WeekOpeningHours[] | null;
  };
};

export const OpeningHoursContext = createContext<OpeningHoursContextType>({
  todaysOpeningHours: { home: null, motor: null },
  weekOpeningHours: { home: null, motor: null },
});

type Props = {
  children: React.ReactNode;
  values?: OpeningHoursContextType;
};

export const OpeningHoursProvider: FC<Props> = ({ children, values }) => {
  const { todaysOpeningHours, weekOpeningHours } = useOpeningHours();

  return (
    <OpeningHoursContext.Provider
      value={values || { todaysOpeningHours, weekOpeningHours }}
    >
      {children}
    </OpeningHoursContext.Provider>
  );
};
