import config from '../config';

const fancyConsoleWelcome = () => {
  // eslint-disable-next-line no-console
  console.log(
    `\n%cPolicy%c\n%cExpert%c\n%cWe're hiring! %${config.CAREERS_URL}`,
    'display: inline-block; font-family: Helvetica, Arial, sans-serif; font-size: 32px; font-weight: 800; line-height: 1.5; color: #fff; background-color: #6214BE; padding: 4px 14px;',
    'background-color: transparent;',
    'display: inline-block; font-family: Helvetica, Arial, sans-serif; font-size: 32px; font-weight: 800; line-height: 1.5; color: #fff; background-color: #FF00A0; padding: 4px 14px; margin-left: 10px;',
    'background-color: transparent;',
    'display: inline-block; font-family: Helvetica, Arial, sans-serif; font-size: 14px; line-height: 1.15rem; margin: 20px 0;',
    'display: inline-block; font-family: Helvetica, Arial, sans-serif; font-size: 14px; line-height: 1.15rem; text-decoration: underline; margin: 20px 0;'
  );
};

export default fancyConsoleWelcome;
