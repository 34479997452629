import React, { useEffect } from 'react';

import fancyConsoleWelcome from '../../helpers/fancyConsoleWelcome';
import CookieModal from '../cookieConsent/CookieModal';
import Footer from '../footer/Footer';
import LiveChat from '../liveChat/LiveChat';
import BottomNav from '../navigation/BottomNav';
import TopNav from '../navigation/TopNav';
import { footerLinks } from './pageLinks';

const PageLayout = ({ children }: { children: React.ReactNode }) => {
  useEffect(() => {
    fancyConsoleWelcome();
  }, []);

  return (
    <>
      <LiveChat />
      <TopNav />
      <main>{children}</main>
      <Footer data={footerLinks} />
      <CookieModal />
      <BottomNav />
    </>
  );
};

export default PageLayout;
