import React, { createContext, FC, ReactNode } from 'react';

import usePhoneNumbers from '../hooks/usePhoneNumbers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PhoneNumbersContextType = { [key: string]: any };

export const PhoneNumbersContext = createContext<PhoneNumbersContextType>({});

type Props = {
  children: ReactNode;
  values?: PhoneNumbersContextType;
};

export const PhoneNumbersProvider: FC<Props> = ({ children, values }) => {
  const phoneNumbers = usePhoneNumbers();

  return (
    <PhoneNumbersContext.Provider value={values || phoneNumbers}>
      {children}
    </PhoneNumbersContext.Provider>
  );
};
