import React, { useEffect, useMemo } from 'react';

import config from '../config';
import getEnvironment from '../helpers/getEnvironment';
import hypertune from '../services/hypertune';

export default function useHypertune() {
  // Trigger a re-render when flags are updated
  const [, setCommitHash] = React.useState<string | null>(
    hypertune.getCommitHash()
  );
  useEffect(() => {
    hypertune.addUpdateListener(setCommitHash);
    return () => {
      hypertune.removeUpdateListener(setCommitHash);
    };
  }, []);

  // Return the Hypertune root node initialized with the current user
  return useMemo(
    () =>
      hypertune.root({
        context: {
          environment: getEnvironment(config.ENVIRONMENT_NAME),
          currentTimestampMS: Date.now(),
        },
      }),
    []
  );
}
