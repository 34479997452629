import { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import capitalize from 'lodash/fp/capitalize';
import get from 'lodash/fp/get';
import times from 'lodash/fp/times';

import config from '../config';

dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

type WeekOpeningHours = { day: string; openingHours: string };

type OpeningHours = {
  todaysOpeningHours: { home: string | null; motor: string | null };
  weekOpeningHours: {
    home: WeekOpeningHours[] | null;
    motor: WeekOpeningHours[] | null;
  };
};

const useOpeningHours = () => {
  const [openingHours, setOpeningHours] = useState<OpeningHours>({
    todaysOpeningHours: { home: null, motor: null },
    weekOpeningHours: { home: null, motor: null },
  });

  const getOpeningHours = async () => {
    const response = await fetch(`${config.TIMES_CDN_URL}/times.json`);
    const json = await response.json();
    const todaysDate = dayjs.tz(Date.now(), 'Europe/London').format('DD/MM');
    const today = dayjs
      .tz(Date.now(), 'Europe/London')
      .format('dddd')
      .toLowerCase();

    const homeHolidayOpeningHours = get(
      `holidays.${todaysDate}.home.default`,
      json
    );
    const motorHolidayOpeningHours = get(
      `holidays.${todaysDate}.motor.default`,
      json
    );
    const homeTodaysHours =
      homeHolidayOpeningHours !== undefined
        ? homeHolidayOpeningHours
        : get(`${today}.home.default`, json);
    const motorTodaysHours =
      motorHolidayOpeningHours !== undefined
        ? motorHolidayOpeningHours
        : get(`${today}.motor.default`, json);

    const formatTimeDisplay = (time: string) => {
      const minutes = time.split(':')[1];

      return dayjs(time, 'HH:mm').format(minutes === '00' ? 'ha' : 'h.mma');
    };

    const homeTodaysOpeningHours = homeTodaysHours
      ? `${formatTimeDisplay(homeTodaysHours.open)} - ${formatTimeDisplay(
          homeTodaysHours.closed
        )}`
      : null;
    const motorTodaysOpeningHours = motorTodaysHours
      ? `${formatTimeDisplay(motorTodaysHours.open)} - ${formatTimeDisplay(
          motorTodaysHours.closed
        )}`
      : null;

    const homeWeekOpeningHours = times((index: number) => {
      const currentDate = dayjs
        .tz(Date.now(), 'Europe/London')
        .add(index, 'day');
      const currentDay = currentDate.format('dddd').toLowerCase();
      const upcomingHoliday = get(
        `holidays.${currentDate.format('DD/MM')}`,
        json
      );

      const upcomingOpeningHours = upcomingHoliday?.home
        ? upcomingHoliday.home.default
        : get(`${currentDay}.home.default`, json);

      const isHolidayNameDefined =
        upcomingHoliday?.home && upcomingHoliday?.name;

      return {
        day: `${capitalize(currentDay)}${
          isHolidayNameDefined ? ` (${upcomingHoliday.name})` : ''
        }`,
        openingHours: upcomingOpeningHours
          ? `${formatTimeDisplay(
              upcomingOpeningHours.open
            )} - ${formatTimeDisplay(upcomingOpeningHours.closed)}`
          : 'Closed',
      };
    }, 7);

    const motorWeekOpeningHours = times((index: number) => {
      const currentDate = dayjs
        .tz(Date.now(), 'Europe/London')
        .add(index, 'day');
      const currentDay = currentDate.format('dddd').toLowerCase();
      const upcomingHoliday = get(
        `holidays.${currentDate.format('DD/MM')}`,
        json
      );

      const upcomingOpeningHours = upcomingHoliday?.motor
        ? upcomingHoliday.motor.default
        : get(`${currentDay}.motor.default`, json);

      const isHolidayNameDefined =
        upcomingHoliday?.motor && upcomingHoliday?.name;

      return {
        day: `${capitalize(currentDay)}${
          isHolidayNameDefined ? ` (${upcomingHoliday.name})` : ''
        }`,
        openingHours: upcomingOpeningHours
          ? `${formatTimeDisplay(
              upcomingOpeningHours.open
            )} - ${formatTimeDisplay(upcomingOpeningHours.closed)}`
          : 'Closed',
      };
    }, 7);

    setOpeningHours({
      todaysOpeningHours: {
        home: homeTodaysOpeningHours,
        motor: motorTodaysOpeningHours,
      },
      weekOpeningHours: {
        home: homeWeekOpeningHours,
        motor: motorWeekOpeningHours,
      },
    });
  };
  useEffect(() => {
    getOpeningHours().catch((error) => {
      console.error(`Error: ${error}`);
      console.error('Something went wrong whilst fetching opening times');
    });
  }, []);
  return useMemo(() => openingHours, [openingHours]);
};

export default useOpeningHours;
