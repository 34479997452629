import useHypertune from './useHypertune';

const useTempIssuesBanner = () => {
  const rootNode = useHypertune();

  return rootNode
    .tempIssuesBanner()
    .get({ displayUrls: [], isEnabled: false, messageToShow: '' });
};

export default useTempIssuesBanner;
