import React, { useState } from 'react';

import {
  deleteCookiesWithPrefixes,
  getUserCookieConsent,
  setUserCookieConsent,
} from '../../services/cookieService';
import { pushCookieConsentEventToDataLayer } from '../../services/windowService';
import CookieSection from './CookieSection';

const ManageCookies = ({
  setShowModal,
  onClose,
}: {
  setShowModal: (showModal: boolean) => void;
  onClose: () => void;
}) => {
  const userCookieConsent = getUserCookieConsent();
  // @ts-ignore
  const performanceCookie = userCookieConsent?.performance;
  // @ts-ignore
  const targetingCookie = userCookieConsent?.targeting;
  const [expandedSection, setExpandedSection] = useState<string | null>(null);
  const [cookieConsentStates, setCookieConsentStates] = useState<{
    [id: string]: boolean;
  }>({
    performance: performanceCookie || false,
    targeting: targetingCookie || false,
  });

  const handleSaveChoices = () => {
    setUserCookieConsent(cookieConsentStates);
    setShowModal(false);
    window.dataLayer.push({
      event: 'updateCookiePreferences',
      label: JSON.stringify(cookieConsentStates),
    });
    if (cookieConsentStates.targeting === true) {
      pushCookieConsentEventToDataLayer('save_targeting_on');
    } else {
      deleteCookiesWithPrefixes('_gcl', '_fbp', '_fbc');
      pushCookieConsentEventToDataLayer('save_targeting_off');
    }
    if (cookieConsentStates.performance === true) {
      pushCookieConsentEventToDataLayer('save_performance_on');
    } else {
      deleteCookiesWithPrefixes('_cs', '_hj');
      pushCookieConsentEventToDataLayer('save_performance_off');
      window.location.reload();
    }
    onClose();
  };

  const handleToggleSection = (id: string) => {
    setExpandedSection((prev) => (prev === id ? null : id));
    pushCookieConsentEventToDataLayer(`expand_${id}`);
  };

  const handleToggleSwitch = (id: string, toggle?: boolean) => {
    setCookieConsentStates((prev) => ({
      ...prev,
      [id]: toggle !== undefined ? toggle : false,
    }));
  };

  const cookieSections = [
    {
      id: 'necessary',
      title: 'Necessary',
      isAlwaysActive: true,
      description:
        'These cookies are needed for the website to work and for us to fulfil our contractual and consumer duty obligations. This means they can’t be switched off. They enable essential functionality such as security, accessibility and live chat support. You can set your browser to block or alert you about these cookies, but it means some parts of the site won’t work.',
    },
    {
      id: 'performance',
      title: 'Performance',
      description:
        'These cookies help us to improve our website by collecting and reporting information on how you use it. The cookies collect information in a way that does not directly identify anyone.',
      cookieValue: performanceCookie,
    },
    {
      id: 'targeting',
      title: 'Targeting / Advertising',
      description:
        'These cookies collect information about your browsing habits to show you personalised adverts. They may be used to build a profile of your interests and show you relevant adverts on other sites. They don’t store directly personal information, but are based on uniquely identifying your browser and internet device. If you don’t allow these cookies, the adverts you see will be less relevant.',
      cookieValue: targetingCookie,
    },
  ];

  return (
    <div className="w-full pt-20px md:pt-100px px-25px md:px-0">
      <div className="py-30px px-25px md:py-50px md:px-40px max-w-600 md:w-smallShape bg-white rounded-radius-15 mx-auto">
        <h3 className="text-fontSize-22 md:text-fontSize-30 tracking-normal pb-5px md:pb-15px">
          Manage cookies
        </h3>
        {cookieSections.map(
          ({ id, title, description, isAlwaysActive, cookieValue }) => (
            <CookieSection
              key={id}
              id={id}
              title={title}
              description={description}
              isAlwaysActive={!!isAlwaysActive}
              expanded={expandedSection === id}
              onToggle={handleToggleSection}
              onToggleSwitch={handleToggleSwitch}
              cookieValue={cookieValue}
            />
          )
        )}
        <div className="flex md:justify-end">
          <button
            type="button"
            onClick={handleSaveChoices}
            className="btn-secondary-impact w-full md:w-158px py-10px md:py-4 mt-30px rounded-radius-15 justify-center items-center font-camptonSemiBold h-37px md:h-50px mb-10px last:mb-0 md:mb-0 text-fontSize-15 md:text-fontSize-16"
          >
            Save my choices
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManageCookies;
