import React, { createContext, FC, ReactNode } from 'react';

import useReviews from '../hooks/useReviews';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ReviewsContextType = { [key: string]: any };

export const ReviewsContext = createContext<ReviewsContextType>({});

type Props = {
  children: ReactNode;
  values?: ReviewsContextType;
};

export const ReviewsProvider: FC<Props> = ({ children, values }) => {
  const reviews = useReviews();

  return (
    <ReviewsContext.Provider value={values || reviews}>
      {children}
    </ReviewsContext.Provider>
  );
};
