import { Link } from 'gatsby';
import { styled } from 'styled-components';

import FacebookIcon from '../../../static/svgIcons/Facebook.svg';
import InstagramIcon from '../../../static/svgIcons/Instagram-Icon.svg';
import PolicyExpertLogo from '../../../static/svgIcons/PolicyExpert_SVG.svg';
import XIcon from '../../../static/svgIcons/X-Icon.svg';
import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonSemiBold,
  SvgIcon,
} from '../../styles/sharedStyled';

export const FooterContainer = styled.footer`
  border-top-width: 1px;
  border-color: ${colors.gray3};
`;

export const FooterContent = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 24px;
`;

export const FooterNavigation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 0;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
  }
`;

export const MainLinksAndSocialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const LogoWrapper = styled.div``;

export const MainLinksContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  @media (min-width: 1024px) {
    margin-left: 40px;
  }

  @media (min-width: 1150px) {
    margin-left: 82px;
  }
`;

export const MainLink = styled(Link)`
  font-family: ${fontFamilyCamptonSemiBold};
  font-weight: 600;
  font-size: 20px;
  letter-spacing: -0.5px;
  line-height: 25px;
  color: ${colors.black};
  margin-bottom: 32px;
  text-align: center;

  @media (min-width: 768px) {
    margin-right: 32px;
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const SocialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  @media (min-width: 1024px) {
    margin-top: 0;
    margin-left: auto;
  }

  @media (min-width: 1150px) {
    flex-direction: row;
  }
`;

export const SocialsText = styled.p`
  font-family: ${fontFamilyCamptonSemiBold};
  font-weight: 600;
  font-size: 20px;
  color: ${colors.pink2};
  line-height: normal;
  letter-spacing: normal;
  margin: 0;
  align-content: center;
`;

export const SocialLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;

  a:not(:first-child) {
    margin: 0 0 0 15px;
  }

  @media (min-width: 1150px) {
    margin-left: 15px;
    margin-top: 0;
  }
`;

export const SocialLink = styled.a`
  width: 42px;
`;

export const DisclaimerContainer = styled.div`
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: ${colors.gray3};
  padding-top: 32px;
`;

export const DisclaimerText = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-weight: 300;
  font-size: 9px;
  line-height: normal;
  letter-spacing: normal;

  @media (min-width: 768px) {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const ImportantLinksContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 32px auto;
  width: auto;
  justify-content: center;
`;

export const ImportantLinkWrapper = styled.div<{ $isNotFirstChild: boolean }>`
  ${({ $isNotFirstChild }) =>
    $isNotFirstChild &&
    `border-color: ${colors.lightBlack}; border-left-width: 1px; padding-left: 8px; margin-left: 8px;`}
`;

export const ImportantLink = styled(Link)`
  font-family: ${fontFamilyCamptonBook};
  font-weight: 300;
  font-size: 16px;
  white-space: nowrap;
  color: ${colors.black};
`;

export const PolicyExpertSvg = styled(PolicyExpertLogo)`
  height: 64px;
  margin-bottom: 32px;

  @media (min-width: 1024px) {
    margin-bottom: 0;
    margin-right: 16px;
  }
`;

export const FacebookSvg = styled(FacebookIcon)`
  ${SvgIcon};
  fill: ${colors.black};
`;

export const XSvg = styled(XIcon)`
  ${SvgIcon};
  fill: ${colors.black};
`;

export const InstagramSvg = styled(InstagramIcon)`
  ${SvgIcon};
  fill: ${colors.black};
`;
