import { useEffect, useMemo, useState } from 'react';

import config from '../config';
import { PhoneNumbersContextType } from '../context/PhoneNumbersProvider';

const usePhoneNumbers = () => {
  const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumbersContextType>({});

  const getPhonesJSON = async () => {
    const response = await fetch(`${config.PHONES_CDN_URL}/phones.json`);
    const json = await response.json();

    setPhoneNumbers(json);
  };
  useEffect(() => {
    getPhonesJSON().catch((error) => {
      console.error(`Error: ${error}`);
      console.error('Something went wrong whilst fetching phone numbers');
    });
  }, []);

  return useMemo(() => phoneNumbers, [phoneNumbers]);
};

export default usePhoneNumbers;
