import Cookies from 'js-cookie';

type cookieConsentStates = {
  [id: string]: boolean;
};

const COOKIE_NAME = 'qmg_consent';

export const setUserCookieConsent = (
  preferences: cookieConsentStates
): void => {
  Cookies.set(COOKIE_NAME, JSON.stringify(preferences), { expires: 180 });
};

export const getUserCookieConsent = (): boolean | undefined => {
  const userCookieConsentPreferences = Cookies.get(COOKIE_NAME);

  try {
    const userPreferences: boolean = userCookieConsentPreferences
      ? JSON.parse(userCookieConsentPreferences)
      : undefined;

    return userPreferences;
  } catch (error) {
    console.error('Error parsing user cookie consent preferences:', error);
    return undefined;
  }
};

export const deleteCookiesWithPrefixes = (...prefixes: string[]) => {
  const allCookies = Cookies.get();
  const cookieDomain =
    process.env.GATSBY_ENVIRONMENT_NAME === 'local'
      ? 'localhost'
      : '.policyexpert.co.uk';

  prefixes.forEach((prefix) => {
    const cookiesToDelete = Object.keys(allCookies).filter((cookieName) =>
      cookieName.startsWith(prefix)
    );

    cookiesToDelete.forEach((cookieName) => {
      Cookies.remove(cookieName, {
        path: '',
        domain: cookieDomain,
        secure: true,
      });
    });
  });
};
