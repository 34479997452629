import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

type Props = {
  children: React.ReactNode;
};

const FormContextProvider: FC<Props> = ({ children }) => {
  const methods = useForm();

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default FormContextProvider;
