import { useLocation } from '@reach/router';

// Todo it seems Object.entries is ES2019
const ObjectFromEntries = (arr: any) =>
  Object.assign({}, ...Array.from(arr, ([k, v]) => ({ [k]: v })));

const useQueryParams = (): Record<string, string> => {
  const urlParams = new URLSearchParams(useLocation().search);
  return ObjectFromEntries(urlParams);
};

export default useQueryParams;
