import React, { useContext } from 'react';

import { Link } from 'gatsby';

import { DisclaimerContext } from '../../context/DisclaimerProvider';
import { UrlLink } from '../pageLayout/pageLinks';
import {
  DisclaimerContainer,
  DisclaimerText,
  FacebookSvg,
  FooterContainer,
  FooterContent,
  FooterNavigation,
  ImportantLink,
  ImportantLinksContainer,
  ImportantLinkWrapper,
  InstagramSvg,
  LogoWrapper,
  MainLink,
  MainLinksAndSocialWrapper,
  MainLinksContainer,
  PolicyExpertSvg,
  SocialLink,
  SocialLinksContainer,
  SocialsContainer,
  SocialsText,
  XSvg,
} from './Footer.style';

type Props = {
  data: UrlLink[][];
};

const Footer = ({ data }: Props) => {
  const disclaimerContext = useContext(DisclaimerContext);

  return (
    <FooterContainer>
      <FooterContent>
        <FooterNavigation>
          <MainLinksAndSocialWrapper>
            <LogoWrapper>
              <Link to="/" data-testid="footer-logo">
                <PolicyExpertSvg alt="Policy Expert Logo" />
              </Link>
            </LogoWrapper>

            <MainLinksContainer>
              {data[0].map(({ label, url }) => (
                <MainLink
                  key={`${label}_footer`}
                  to={url}
                  data-testid="footer-link"
                >
                  {label}
                </MainLink>
              ))}
            </MainLinksContainer>

            <SocialsContainer>
              <SocialsText>Connect with us on</SocialsText>
              <SocialLinksContainer>
                <SocialLink
                  target="_blank"
                  href="https://www.facebook.com/policyexpert"
                  aria-label="facebook"
                  rel="noopener noreferrer"
                  data-testid="footer-social"
                >
                  <FacebookSvg />
                </SocialLink>
                <SocialLink
                  target="_blank"
                  href="https://twitter.com/PolicyExpert"
                  aria-label="X (formerly Twitter)"
                  rel="noopener noreferrer"
                  data-testid="footer-social"
                >
                  <XSvg />
                </SocialLink>
                <SocialLink
                  target="_blank"
                  href="https://www.instagram.com/policyexpert"
                  aria-label="instagram"
                  rel="noopener noreferrer"
                  data-testid="footer-social"
                >
                  <InstagramSvg />
                </SocialLink>
              </SocialLinksContainer>
            </SocialsContainer>
          </MainLinksAndSocialWrapper>
        </FooterNavigation>
        <DisclaimerContainer>
          <DisclaimerText data-testid="footer-disclaimer">
            {`© QMetric Group Limited ${new Date().getFullYear()}. All rights reserved. Policy Expert is a trading name of QMetric Group Limited who are authorised and regulated by the Financial Conduct Authority under firm reference 529506. Registered address: 110 Bishopsgate, London, EC2N 4AY. Registration number is 07151701, registered in England and Wales.`}
            {disclaimerContext?.currentDisclaimer}
          </DisclaimerText>
        </DisclaimerContainer>
        <ImportantLinksContainer>
          {data[1].map(({ label, url }, index) => (
            <ImportantLinkWrapper key={label} $isNotFirstChild={index !== 0}>
              <ImportantLink to={url} data-testid="footer-internal-link">
                {label}
              </ImportantLink>
            </ImportantLinkWrapper>
          ))}
        </ImportantLinksContainer>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
