import { useEffect, useMemo, useState } from 'react';

import { ReviewsContextType } from '../context/ReviewsProvider';

const useReviews = () => {
  const [reviews, setReviews] = useState<ReviewsContextType>({});

  const getReviewsJSON = async () => {
    const response = await fetch(
      'https://reviews.policyexpert.co.uk/reviews.json'
    );
    const json = await response.json();

    setReviews(json);
  };
  useEffect(() => {
    getReviewsJSON().catch((error) => {
      console.error(`Error: ${error}`);
      console.error('Something went wrong whilst fetching reviews');
    });
  }, []);

  return useMemo(() => reviews, [reviews]);
};

export default useReviews;
