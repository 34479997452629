/* eslint-disable */

import * as sdk from 'hypertune';

export const queryCode = `id/e489416d-1a86-4d59-90c3-bab090b8b500`;

export const query = {
  Query: {
    objectTypeName: 'Query',
    selection: {
      root: {
        fieldArguments: { __isPartialObject__: true },
        fieldQuery: {
          Root: {
            objectTypeName: 'Root',
            selection: {
              enableCareersChanges: { fieldArguments: {}, fieldQuery: null },
              enableHECLandingPage: { fieldArguments: {}, fieldQuery: null },
              tempIssuesBanner: {
                fieldArguments: {},
                fieldQuery: {
                  TempIssuesBanner: {
                    objectTypeName: 'TempIssuesBanner',
                    selection: {
                      isEnabled: { fieldArguments: {}, fieldQuery: null },
                      messageToShow: { fieldArguments: {}, fieldQuery: null },
                      displayUrls: { fieldArguments: {}, fieldQuery: null },
                    },
                  },
                },
              },
              enableTempIssuesBanner: { fieldArguments: {}, fieldQuery: null },
              enablePetChanges: { fieldArguments: {}, fieldQuery: null },
            },
          },
        },
      },
    },
  },
};

function mergeQueryAndArgs(
  query: sdk.Query<sdk.ObjectValueWithVariables>,
  queryArgs: sdk.ObjectValueWithVariables | null,
  unwrapObjectArgs = false
): sdk.Query<sdk.ObjectValueWithVariables> {
  return Object.fromEntries(
    Object.entries(query).map(([objectTypeName, fragment]) => {
      const objectArgs = unwrapObjectArgs
        ? queryArgs &&
          queryArgs[objectTypeName] &&
          queryArgs[objectTypeName] instanceof Object
          ? (queryArgs[objectTypeName] as sdk.ObjectValueWithVariables)
          : null
        : queryArgs;

      return [
        objectTypeName,
        {
          objectTypeName,
          selection: Object.fromEntries(
            Object.entries(fragment.selection).map(
              ([fieldName, { fieldQuery }]) => {
                const fieldArgs =
                  objectArgs &&
                  objectArgs[fieldName] &&
                  objectArgs[fieldName] instanceof Object
                    ? (objectArgs[fieldName] as sdk.ObjectValueWithVariables)
                    : null;

                return [
                  fieldName,
                  {
                    fieldArguments: {
                      ...(fieldArgs && fieldArgs.args
                        ? (fieldArgs.args as sdk.ObjectValueWithVariables)
                        : {}),
                    },
                    fieldQuery: fieldQuery
                      ? mergeQueryAndArgs(fieldQuery, fieldArgs, true)
                      : null,
                  },
                ];
              }
            )
          ),
        },
      ];
    })
  );
}

/**
 * @deprecated use '@vercel/flags/providers/hypertune' package instead.
 */
export const vercelFlagDefinitions = {
  enableCareersChanges: {
    options: [
      { label: 'Off', value: false },
      { label: 'On', value: true },
    ],
    origin:
      'https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EenableCareersChanges',
  },
  enableHECLandingPage: {
    options: [
      { label: 'Off', value: false },
      { label: 'On', value: true },
    ],
    origin:
      'https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EenableHECLandingPage',
  },
  'tempIssuesBanner.isEnabled': {
    options: [
      { label: 'Off', value: false },
      { label: 'On', value: true },
    ],
    origin:
      'https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EtempIssuesBanner%3EisEnabled',
  },
  'tempIssuesBanner.messageToShow': {
    options: [],
    origin:
      'https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EtempIssuesBanner%3EmessageToShow',
  },
  enableTempIssuesBanner: {
    options: [
      { label: 'Off', value: false },
      { label: 'On', value: true },
    ],
    origin:
      'https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EenableTempIssuesBanner',
  },
  enablePetChanges: {
    options: [
      { label: 'Off', value: false },
      { label: 'On', value: true },
    ],
    origin:
      'https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EenablePetChanges',
  },
};

export type Rec = {};

export const EnvironmentEnumValues = ['LOCAL', 'UAT', 'LIVE'] as const;
export type Environment = (typeof EnvironmentEnumValues)[number];

export type Rec2 = {
  environment: Environment;
  currentTimestampMS: number;
};

export type RootArgs = {
  context: Rec2;
};

export type TempIssuesBanner = {
  isEnabled: boolean;
  messageToShow: string;
  displayUrls: string[];
};

const tempIssuesBannerFallback = {
  isEnabled: false,
  messageToShow: '',
  displayUrls: [],
};

export class TempIssuesBannerNode extends sdk.Node {
  override typeName = 'TempIssuesBanner' as const;

  get(
    fallback: TempIssuesBanner = tempIssuesBannerFallback as TempIssuesBanner
  ): TempIssuesBanner {
    const getQuery = null;
    return this.evaluate(getQuery, fallback) as TempIssuesBanner;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EtempIssuesBanner%3EisEnabled})
   */
  isEnabled(args: Rec = {}): sdk.BooleanNode {
    const props0 = this.getField('isEnabled', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'BooleanExpression') {
      const node = new sdk.BooleanNode(props0);
      return node;
    }

    const node = new sdk.BooleanNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EtempIssuesBanner%3EmessageToShow})
   */
  messageToShow(args: Rec = {}): sdk.StringNode {
    const props0 = this.getField('messageToShow', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'StringExpression') {
      const node = new sdk.StringNode(props0);
      return node;
    }

    const node = new sdk.StringNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EtempIssuesBanner%3EdisplayUrls})
   */
  displayUrls(args: Rec = {}, listFallbackLength = 0): sdk.StringNode[] {
    const props0 = this.getField('displayUrls', args);
    const expression0 = props0.expression;

    return new sdk.Node(props0)._getItems(listFallbackLength).map((props1) => {
      const expression1 = props1.expression;

      if (expression1 && expression1.type === 'StringExpression') {
        const node = new sdk.StringNode(props1);
        return node;
      }

      const node = new sdk.StringNode(props1);
      node._logUnexpectedTypeError();
      return node;
    });
  }
}

export type Root = {
  enableCareersChanges: boolean;
  enableHECLandingPage: boolean;
  tempIssuesBanner: TempIssuesBanner;
  enableTempIssuesBanner: boolean;
  enablePetChanges: boolean;
};

const rootFallback = {
  enableCareersChanges: false,
  enableHECLandingPage: false,
  tempIssuesBanner: { isEnabled: false, messageToShow: '', displayUrls: [] },
  enableTempIssuesBanner: false,
  enablePetChanges: false,
};

export class RootNode extends sdk.Node {
  override typeName = 'Root' as const;

  get(fallback: Root = rootFallback as Root): Root {
    const getQuery = null;
    return this.evaluate(getQuery, fallback) as Root;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EenableCareersChanges})
   */
  enableCareersChanges(args: Rec = {}): sdk.BooleanNode {
    const props0 = this.getField('enableCareersChanges', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'BooleanExpression') {
      const node = new sdk.BooleanNode(props0);
      return node;
    }

    const node = new sdk.BooleanNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EenableHECLandingPage})
   */
  enableHECLandingPage(args: Rec = {}): sdk.BooleanNode {
    const props0 = this.getField('enableHECLandingPage', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'BooleanExpression') {
      const node = new sdk.BooleanNode(props0);
      return node;
    }

    const node = new sdk.BooleanNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EtempIssuesBanner})
   */
  tempIssuesBanner(args: Rec = {}): TempIssuesBannerNode {
    const props0 = this.getField('tempIssuesBanner', args);
    const expression0 = props0.expression;

    if (
      expression0 &&
      expression0.type === 'ObjectExpression' &&
      expression0.objectTypeName === 'TempIssuesBanner'
    ) {
      return new TempIssuesBannerNode(props0);
    }

    const node = new TempIssuesBannerNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EenableTempIssuesBanner})
   */
  enableTempIssuesBanner(args: Rec = {}): sdk.BooleanNode {
    const props0 = this.getField('enableTempIssuesBanner', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'BooleanExpression') {
      const node = new sdk.BooleanNode(props0);
      return node;
    }

    const node = new sdk.BooleanNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }

  /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/2959/main/draft/logic?selected_field_path=root%3EenablePetChanges})
   */
  enablePetChanges(args: Rec = {}): sdk.BooleanNode {
    const props0 = this.getField('enablePetChanges', args);
    const expression0 = props0.expression;

    if (expression0 && expression0.type === 'BooleanExpression') {
      const node = new sdk.BooleanNode(props0);
      return node;
    }

    const node = new sdk.BooleanNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }
}

/**
 * Welcome to Hypertune, the most powerful feature flag, A/B testing and app
 * configuration platform.
 *
 * Follow the quickstart: https://docs.hypertune.com/quickstart
 *
 * This is your schema, written in GraphQL. Use Boolean for feature flags,
 * custom enums for flags with more than two states, Int for numeric flags like
 * limits and timeouts, Strings for in-app copy, and custom object and list types
 * for more complex app configuration.
 *
 * Once you've defined your schema, head to the Logic tab.
 */
export type Source = {
  /**
   * You can add arguments to any field in your schema, which you can then
   * reference when defining your logic. We've added a 'context' argument on your
   * root field already, which contains details of the current 'user'.
   */
  root: Root;
};

const sourceFallback = {
  root: {
    enableCareersChanges: false,
    enableHECLandingPage: false,
    tempIssuesBanner: { isEnabled: false, messageToShow: '', displayUrls: [] },
    enableTempIssuesBanner: false,
    enablePetChanges: false,
  },
};

export type Rec4 = {
  args: RootArgs;
};

export type Rec3 = {
  root: Rec4;
};

/**
 * Welcome to Hypertune, the most powerful feature flag, A/B testing and app
 * configuration platform.
 *
 * Follow the quickstart: https://docs.hypertune.com/quickstart
 *
 * This is your schema, written in GraphQL. Use Boolean for feature flags,
 * custom enums for flags with more than two states, Int for numeric flags like
 * limits and timeouts, Strings for in-app copy, and custom object and list types
 * for more complex app configuration.
 *
 * Once you've defined your schema, head to the Logic tab.
 */
export class SourceNode extends sdk.Node {
  override typeName = 'Query' as const;

  get(args: Rec3, fallback: Source = sourceFallback as Source): Source {
    const getQuery = mergeQueryAndArgs(query, args);
    return this.evaluate(getQuery, fallback) as Source;
  }

  /**
   * You can add arguments to any field in your schema, which you can then
   * reference when defining your logic. We've added a 'context' argument on your
   * root field already, which contains details of the current 'user'.
   */
  root(args: RootArgs): RootNode {
    const props0 = this.getField('root', args);
    const expression0 = props0.expression;

    if (
      expression0 &&
      expression0.type === 'ObjectExpression' &&
      expression0.objectTypeName === 'Root'
    ) {
      return new RootNode(props0);
    }

    const node = new RootNode(props0);
    node._logUnexpectedTypeError();
    return node;
  }
}

export function initializeHypertune(
  variableValues: Rec,
  options: sdk.InitializeOptions = {}
): SourceNode {
  const defaultOptions = {
    query,
    queryCode,
    variableValues,
  };

  return sdk.initialize(SourceNode, { ...defaultOptions, ...options });
}
