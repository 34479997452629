import React from 'react';

import config from '../../config';
import usePetChanges from '../../hooks/usePetChanges';
import { bottomMenuLinks, formatUrl } from '../pageLayout/pageLinks';
import {
  MainContainer,
  MenuContainer,
  MenuHeader,
  ProductButton,
  ProductButtonsContainer,
  SectionContainer,
  SubContainer,
} from './BottomNavMenu.style';
import NavLink from './NavLink';

type Props = {
  closeMenu: () => void;
};

const BottomNavMenu = ({ closeMenu }: Props) => {
  const isPetChangesEnabled = usePetChanges();

  return (
    <MainContainer data-testid="bottom-nav-menu">
      <SubContainer>
        <MenuContainer>
          <MenuHeader>Our products</MenuHeader>
          <SectionContainer>
            {bottomMenuLinks[0].map(({ url, label, isExternal }) => (
              <NavLink
                key={`${label}_nav_menu`}
                url={formatUrl({ url, isPetChangesEnabled })}
                onClick={closeMenu}
                isExternal={isExternal}
                className="nav-menu-link mr-auto"
              >
                {label}
              </NavLink>
            ))}
          </SectionContainer>
          <MenuHeader $hasMarginTop>Help and resources</MenuHeader>
          <SectionContainer>
            {bottomMenuLinks[1].map(({ url, label, isExternal }) => (
              <NavLink
                key={`${label}_nav_menu`}
                url={url}
                onClick={closeMenu}
                isExternal={isExternal}
                className="nav-menu-link mr-auto"
              >
                {label}
              </NavLink>
            ))}
          </SectionContainer>
          <MenuHeader $hasMarginTop>Manage your policy online</MenuHeader>
          <SectionContainer>
            {bottomMenuLinks[2].map(({ url, label, isExternal }) => (
              <NavLink
                key={`${label}_nav_menu`}
                url={url}
                onClick={closeMenu}
                isExternal={isExternal}
                className="nav-menu-link mr-auto"
              >
                {label}
              </NavLink>
            ))}
          </SectionContainer>
          <MenuHeader $hasMarginTop>Get a quote</MenuHeader>
          <ProductButtonsContainer $isPetChangesEnabled={isPetChangesEnabled}>
            <ProductButton
              href={config.HOME_URL}
              $isPetChangesEnabled={isPetChangesEnabled}
              data-testid="bottom-menu-home"
            >
              Home
            </ProductButton>
            <ProductButton
              href={config.CAR_URL}
              $isPetChangesEnabled={isPetChangesEnabled}
              data-testid="bottom-menu-car"
            >
              Car
            </ProductButton>
            {isPetChangesEnabled && (
              <ProductButton
                href={config.PET_URL}
                $isPetChangesEnabled={isPetChangesEnabled}
                data-testid="bottom-menu-pet"
              >
                Pet
              </ProductButton>
            )}
          </ProductButtonsContainer>
        </MenuContainer>
      </SubContainer>
    </MainContainer>
  );
};

export default BottomNavMenu;
