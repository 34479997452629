import React, { createContext, ReactNode, useContext, useState } from 'react';

type CookieModalContextType = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const CookieModalContext = createContext<CookieModalContextType | undefined>(
  undefined
);

export { CookieModalContext };

export const CookieModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <CookieModalContext.Provider value={{ showModal, setShowModal }}>
      {children}
    </CookieModalContext.Provider>
  );
};

export const useCookieModal = () => {
  const context = useContext(CookieModalContext);
  if (!context) {
    throw new Error('useCookieModal must be used within a CookieModalProvider');
  }
  return context;
};
