import React, { FC, ReactNode } from 'react';

import { Link } from 'gatsby';

type Props = {
  children: ReactNode;
  url: string;
  className: string;
  isExternal?: boolean;
  onClick?: () => void;
};

const NavLink: FC<Props> = ({
  children,
  className,
  url,
  isExternal,
  onClick,
}) =>
  isExternal ? (
    <a href={url} className={className}>
      {children}
    </a>
  ) : (
    <Link to={url} onClick={onClick} className={className}>
      {children}
    </Link>
  );

export default NavLink;
