import useHypertune from './useHypertune';
import useQueryParams from './useQueryParams';

const usePetChanges = () => {
  const { usePetChanges } = useQueryParams();

  const rootNode = useHypertune();

  if (usePetChanges === 'false') {
    return false;
  }

  return rootNode.enablePetChanges().get(false) || usePetChanges === 'true';
};

export default usePetChanges;
