import React, { useEffect, useState } from 'react';
import Modal, { RenderModalBackdropProps } from 'react-overlays/Modal';

import { useLocation } from '@reach/router';
import { Link } from 'gatsby';

import { useCookieModal } from '../../context/CookieModalProvider';
import {
  getUserCookieConsent,
  setUserCookieConsent,
} from '../../services/cookieService';
import { pushCookieConsentEventToDataLayer } from '../../services/windowService';
import ManageCookies from './ManageCookies';

const CookieModal = () => {
  const { pathname } = useLocation();
  const { showModal, setShowModal } = useCookieModal();

  const [showManageCookies, setShowManageCookies] = useState(false);
  const hasSetCookieConsent = getUserCookieConsent();
  const isCookiePolicyPage = pathname === '/cookie-policy/';

  const renderBackdrop = (props: RenderModalBackdropProps) => (
    <div {...props} className="fixed z-2040 inset-0 bg-pxBackDrop" />
  );

  useEffect(() => {
    document.body.style.overflowY =
      showModal || isCookiePolicyPage ? 'auto' : '';

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [showModal, isCookiePolicyPage]);

  useEffect(() => {
    if (!isCookiePolicyPage && !hasSetCookieConsent) {
      setShowModal(true);
    }
  }, [isCookiePolicyPage, hasSetCookieConsent, setShowModal]);

  const allCookiesAccepted = {
    performance: true,
    targeting: true,
  };

  const handleAcceptAllCookies = () => {
    setUserCookieConsent(allCookiesAccepted);
    window.dataLayer.push({
      event: 'updateCookiePreferences',
      label: JSON.stringify(allCookiesAccepted),
    });
    pushCookieConsentEventToDataLayer('accept_all');
    setShowModal(false);
  };

  const handleCookieLink = () => {
    pushCookieConsentEventToDataLayer('cookie_policy_link');
    if (isCookiePolicyPage) {
      setShowModal(false);
    }
  };

  const handleCloseManageCookies = () => {
    setShowManageCookies(false);
  };

  const handleShowManageCookies = () => {
    setShowManageCookies(true);
    pushCookieConsentEventToDataLayer('manage_cookies');
  };

  return (
    <Modal
      show={showModal}
      renderBackdrop={renderBackdrop}
      aria-labelledby="modal-label"
      data-testid="cookie-consent-modal"
      className="fixed w-full inset-y-0 right-0 z-2040 outline-none bg-transparent rounded-none flex justify-center overflow-y-scroll"
    >
      {showManageCookies ? (
        <ManageCookies
          setShowModal={setShowModal}
          onClose={handleCloseManageCookies}
        />
      ) : (
        <div className="pt-20px md:pt-100px px-25px md:px-0">
          <div className="w-full py-30px px-25px md:py-50px md:px-40px max-w-600 md:w-smallShape bg-white rounded-radius-15 mx-auto">
            <h3 className="mb-15px md:mb-20px text-fontSize-22 md:text-fontSize-30 md:pb-0 tracking-normal">
              Cookie preferences
            </h3>
            <p className="text-fontSize-14 md:text-fontSize-15 tracking-normal mb-20px">
              At Policy Expert we use cookies to ensure that our website works
              properly. We can also use cookies to improve and personalise your
              experience, understand how you use our website and for our
              advertising purposes.
            </p>
            <p className="text-fontSize-14 md:text-fontSize-15 tracking-normal mb-20px">
              You can agree to all cookies by clicking on ‘Accept all’ or manage
              your cookies below.
            </p>
            <p className="text-fontSize-14 md:text-fontSize-15 tracking-normal mb-20px md:mb-30px">
              For more information about the cookies on our website and how we
              use them please see our{' '}
              <Link
                onClick={handleCookieLink}
                data-testid="cookie-policy-link"
                to="/cookie-policy/"
                className="tracking-normal font-semibold"
              >
                Cookie Policy.
              </Link>
            </p>
            <div className="flex flex-col md:flex-row lg:flex-auto md:gap-20px">
              <button
                type="button"
                onClick={handleShowManageCookies}
                className="btn-secondary-impact w-full md:w-250px py-10px md:py-4 rounded-radius-15 justify-center items-center font-camptonSemiBold h-37px md:h-50px mb-10px last:mb-0 md:mb-0 text-fontSize-15 md:text-fontSize-16"
              >
                Manage cookies
              </button>
              <button
                type="button"
                onClick={handleAcceptAllCookies}
                className="btn-primary w-full md:w-250px py-10px md:py-4 rounded-radius-15 justify-center items-center font-camptonSemiBold h-37px md:h-50px mb-10px last:mb-0 md:mb-0 text-fontSize-15 md:text-fontSize-16"
              >
                Accept all
              </button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default CookieModal;
